import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Layer, Source } from "react-mapbox-gl"
import { debounce } from "lodash"

import Tooltip from "./tooltip"
import colors from "../../theme/sections/colors"

const countriesSourceId = `countries-source`
const countriesSourceLayer = `ne_10m_admin_0_countries-6j33id` // https://github.com/nvkelso/natural-earth-vector/blob/bf7720b54dd9ac2d4d7f735174901b3862b5362a/geojson/ne_10m_admin_0_countries.geojson

class Countries extends React.Component {
  countryHovered = null
  countries = this.props.data.map((item) => item.Country)
  state = {
    countryData: null,
  }

  componentDidMount() {
    this.setBindings()

    if (typeof window !== `undefined`) {
      window.addEventListener(`orientationchange`, this.winOrientationChange, {
        passive: true,
      })
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener(
        `orientationchange`,
        this.winOrientationChange,
        { passive: true }
      )
    }
  }

  render() {
    const { countryData } = this.state

    return (
      <React.Fragment>
        {countryData &&
          ReactDOM.createPortal(
            <Tooltip data={countryData} />,
            document.querySelector(`body`)
          )}

        <Source
          id={countriesSourceId}
          tileJsonSource={{
            type: `vector`,
            url: `mapbox://cint.3687tzar`,
          }}
        />

        <Layer
          id="countries"
          sourceId={countriesSourceId}
          sourceLayer={countriesSourceLayer}
          before="road-label"
          type="fill"
          paint={{
            "fill-color": [
              `case`,
              [`==`, [`feature-state`, `hovered`], true],
              colors.colorRed,
              colors.colorPurpleLight,
            ],
            "fill-outline-color": colors.colorGreyDark,
          }}
          filter={[
            `any`,
            [`in`, `NAME`, ...this.countries],
            [`in`, `ISO_A3`, ...this.countries],
            [`in`, `FIPS_10_`, ...this.countries],
            [`in`, `FORMAL_EN`, ...this.countries],
          ]}
        />
      </React.Fragment>
    )
  }

  setBindings = () => {
    const { map } = this.props

    map.on(`mousemove`, `countries`, this.mouseMove)
    map.on(`mouseleave`, `countries`, this.mouseLeave)
  }

  mouseMove = (e) => {
    const { map } = this.props
    if (!map || !e.features.length) return

    const { countryHovered } = this
    const country = e.features[0]
    if (country && countryHovered && country.id === countryHovered.id) return

    if (countryHovered) this.setCountryHovered(countryHovered.id, false)
    this.setCountryHovered(country.id, true)
    // map.getCanvas().style.cursor = "pointer"
    this.setState({ countryData: this.getCountryData(country) })
    this.countryHovered = country
  }

  mouseLeave = () => {
    const { map } = this.props
    const { countryHovered } = this
    if (!map || !countryHovered) return

    this.setCountryHovered(countryHovered.id, false)
    // map.getCanvas().style.cursor = null
    this.countryHovered = null
    this.setState({ countryData: null })
  }

  setCountryHovered = (id, hovered) => {
    const { map } = this.props
    if (!map) return

    map.setFeatureState(
      { id: id, source: countriesSourceId, sourceLayer: countriesSourceLayer },
      { hovered: hovered }
    )
  }

  getCountryData = (countryFeat) => {
    const names = [
      countryFeat.properties.NAME,
      countryFeat.properties.ISO_A3,
      countryFeat.properties.FIPS_10_,
      countryFeat.properties.FORMAL_EN,
    ]

    return this.props.data.find((c) => names.includes(c.Country)) || null
  }

  winOrientationChange = debounce(() => {
    this.mouseLeave()
  }, 200)
}

Countries.propTypes = {
  map: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
}

export default Countries
