import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import Hero from "../components/hero"
import Intro from "../components/intro"
import TextSections from "../components/text-sections"
import Blurbs from "../components/blurbs"
import Accordion from "../components/accordion"
import Grid from "../components/dotgrid/grid"
import Row from "../components/dotgrid/row"
import Cell from "../components/dotgrid/cell"
import PanelBookMap from "../components/panelbook-map"
import PanelBookTable from "../components/panelbook-table"
import { fixPanelBook } from "../utils/panelbook"

const InsightsExchange = ({ data, pageContext }) => {
  const {
    metaDefaults,
    metaWpGlobal,
    metaWpPage,
    hideFooterSections,
    hero,
    intro,
    textSections,
    panelBook,
    blurbs,
    accordion,
    wp,
    siteMessage,
  } = data
  const { panellistFootnote } = wp.acf
  const { translations, menus } = pageContext

  const panelBookFixed = fixPanelBook(panelBook.nodes)

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      hideFooterSections={hideFooterSections.acf}
      siteMessage={siteMessage}
    >
      <MetaWp page={metaWpPage} global={metaWpGlobal} defaults={metaDefaults} />

      <Hero data={hero} />

      <Intro data={intro} />

      <TextSections data={textSections} />

      <PanelBookMap data={panelBookFixed} />

      <PanelBookTable data={panelBookFixed} />

      {panellistFootnote && panelBookFixed && panelBookFixed.length > 0 && (
        <Grid noTopPadding>
          <Row pullY={2}>
            <Cell size={13} mq={{ xsmallDown: { size: 19 } }}>
              <p
                className="styled-p-small"
                dangerouslySetInnerHTML={{ __html: panellistFootnote }}
              />
            </Cell>
          </Row>
        </Grid>
      )}

      <Blurbs data={blurbs} />

      <Accordion data={accordion} />
    </Layout>
  )
}

export default InsightsExchange

InsightsExchange.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpPageFragment
    ...SiteMessageFragment
    ...HideFooterSectionsPageFragment

    ...HeroPageFragment
    ...IntroPageFragment
    ...TextSectionsPageFragment
    ...PanelBookFragment
    ...BlurbsPageFragment
    ...AccordionPageFragment

    wp: wordpressPage(id: { eq: $id }) {
      acf {
        panellistFootnote: insight_exchange_panellist_footnote
      }
    }
  }
`
