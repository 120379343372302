import { startsWith, trimStart } from "lodash"

const fixPanelBook = (panelBook) => {
  if (panelBook && panelBook.length) {
    panelBook.forEach((n) => {
      delete n.id
    })
  } else panelBook = null

  return panelBook
}

const normalizePanelBookHeader = (header) =>
  startsWith(header, `_`)
    ? header === `_80`
      ? trimStart(header, `_`).concat(`+`)
      : trimStart(header, `_`).replace(`_`, `-`)
    : header

export { fixPanelBook, normalizePanelBookHeader }
