import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import ReactMapboxGl, { ZoomControl } from "react-mapbox-gl"
import MultiTouch from "mapbox-gl-multitouch"
import "mapbox-gl/dist/mapbox-gl.css"

import Countries from "./countries"
import mapboxConfig from "../../config/mapbox"

const Container = styled.div`
  background-color: ${(props) => props.theme.colorGreyDark};

  .mapboxgl-ctrl-logo {
    transform: scale(0.75);
    transform-origin: bottom left;
  }

  .mapboxgl-ctrl-attrib {
    font-size: ${rem(10)};
  }

  .mapbox-improve-map {
    display: none;
  }
`

const isSafari =
  typeof window !== `undefined`
    ? !!window.navigator.userAgent.match(/^((?!chrome|android).)*safari/i)
    : null

const isFirefox =
  typeof window !== `undefined`
    ? window.navigator.userAgent.match(/firefox/i)
    : null

let MapGL = null

if (typeof window !== `undefined`) {
  // eslint-disable-next-line new-cap
  MapGL = ReactMapboxGl({
    accessToken: mapboxConfig.accessToken,
    preserveDrawingBuffer: isSafari || isFirefox, // allows map printing in Safari/Firefox
    scrollZoom: false,
  })
}

const PanelBookMap = ({ data }) => {
  const [map, setMap] = useState(null)

  if (!data || !data.length) return null

  const mapLoad = (map) => {
    map.addControl(new MultiTouch())
    map.dragRotate.disable()
    map.touchZoomRotate.disableRotation()
    setMap(map)
  }

  const winWidth = typeof window !== `undefined` ? window.innerWidth : 0
  let zoom = 0
  if (winWidth >= 1024) zoom = 0.9
  if (winWidth >= 1280) zoom = 1.3
  if (winWidth >= 1680) zoom = 1.5
  if (winWidth >= 1900) zoom = 1.8
  if (winWidth >= 2100) zoom = 2.0
  if (winWidth >= 2300) zoom = 2.1
  if (winWidth >= 2500) zoom = 2.3

  return (
    <Container>
      {MapGL && (
        <MapGL
          zoom={[zoom]}
          center={[20, 30]}
          onStyleLoad={mapLoad}
          style={mapboxConfig.style}
          containerStyle={{
            width: `100%`,
            height: `80vh`,
          }}
        >
          {map && (
            <React.Fragment>
              <ZoomControl position="top-right" />

              <Countries data={data} map={map} />
            </React.Fragment>
          )}
        </MapGL>
      )}
    </Container>
  )
}

PanelBookMap.propTypes = {
  data: PropTypes.array,
}

export default PanelBookMap
