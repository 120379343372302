import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Table from "./table"
import Filter from "./filter"
import Grid from "../dotgrid/grid"
import Cell from "../dotgrid/cell"
import Row from "../dotgrid/row"
import { StoreConsumer } from "../../store"

const Empty = styled.div.attrs({ className: `styled-h4` })`
  color: ${(props) => props.theme.colorPurpleLight};
`

const Spacer = styled.div`
  height: 90vh;
`

class PanelBookTable extends React.Component {
  state = {
    data: this.props.data,
    filterValue: ``,
  }

  render() {
    if (!this.props.data || !this.props.data.length) return null

    const { data, filterValue } = this.state

    return (
      <Grid>
        <Row>
          <Cell
            size={4}
            mq={{ largeDown: { size: 8 }, xsmallDown: { size: 12 } }}
          >
            <Filter value={filterValue} onChange={this.filterChange} />
          </Cell>
        </Row>

        <Row pushY={0.5}>
          {data && data.length ? (
            <Cell size={23}>
              <Table data={data} />
            </Cell>
          ) : (
            <Cell size={23}>
              <StoreConsumer>
                {({ translations }) => (
                  <Empty
                    dangerouslySetInnerHTML={{
                      __html: translations[`panelbook_table_not_found`],
                    }}
                  />
                )}
              </StoreConsumer>
            </Cell>
          )}

          {filterValue.trim() && <Spacer />}
        </Row>
      </Grid>
    )
  }

  filterChange = (e) => {
    const { data } = this.props
    const { filterValue } = this.state
    const value = e.currentTarget.value

    this.setState({ filterValue: value })

    const searchValue = value.trim()
    if (searchValue !== filterValue) {
      let newData = data
      if (searchValue) {
        newData = data.filter((item) =>
          item.Country.toLowerCase().includes(searchValue.toLowerCase())
            ? item
            : false
        )
      }
      this.setState({ data: newData })
    }
  }
}

PanelBookTable.propTypes = {
  data: PropTypes.array,
}

export default PanelBookTable
