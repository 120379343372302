import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem, rgba } from "polished"
import { usePopper } from "react-popper"
import NumberFormat from "react-number-format"

import { normalizePanelBookHeader } from "../../utils/panelbook"

const Popup = styled.div`
  max-width: ${rem(280)};
  min-width: ${rem(200)};
  padding: 1em;
  z-index: ${(props) => props.theme.ziPanelbookMapTooltip};
  font-size: 1rem;
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorPurple};
  animation: ${(props) => props.theme.fadeIn} 0.2s linear;
`

const Heading = styled.h3`
  font-size: 1.125em;
`

const Info = styled.dl`
  font-size: 0.875em;
  font-variant-numeric: tabular-nums;

  dl + dl {
    margin-top: 0.1em;
  }

  dl[data-term="Total"],
  dl[data-term="Male"],
  dl[data-term="14-22"] {
    border-top: 1px solid ${(props) => rgba(props.theme.colorWhite, 0.2)};
    margin-top: 0.5em;
    padding-top: 0.5em;
  }

  dt,
  dd {
    display: inline-block;
  }

  dt {
    margin-right: 0.3em;
    opacity: 0.7;
    width: 30%;
  }

  dd {
    margin-left: auto;
    width: 65%;
    text-align: right;
    position: relative;

    .bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: ${(props) => rgba(props.theme.colorWhite, 0.2)};
      transition: width 400ms ease;
    }
  }

  dl[data-term="Total"] dd::before {
    display: none;
  }
`

function generateGetBoundingClientRect(x = 0, y = 0) {
  return () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  })
}

const virtualElement = {
  getBoundingClientRect: generateGetBoundingClientRect(),
}

const Tooltip = ({ data }) => {
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes, update } = usePopper(
    virtualElement,
    popperElement,
    {
      placement: `top`,
      strategy: `fixed`,
      modifiers: [
        {
          name: `offset`,
          options: {
            offset: [0, 30],
          },
        },
      ],
    }
  )

  const name = data.Country
  const info = Object.entries(data).filter((i) => i[0] !== `Country`) || []

  const mouseMove = ({ clientX: x, clientY: y }) => {
    virtualElement.getBoundingClientRect = generateGetBoundingClientRect(x, y)
    if (update) update()
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener(`mousemove`, mouseMove, { passive: true })
    }

    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener(`mousemove`, mouseMove, { passive: true })
      }
    }
  })

  return (
    <Popup ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <Heading>{name}</Heading>

      {info.length && (
        <Info>
          {info.map(([term, description], i) => (
            <dl key={i} data-term={normalizePanelBookHeader(term)}>
              <dt>{normalizePanelBookHeader(term)}</dt>

              <dd>
                {term === `Total` ? (
                  <NumberFormat
                    value={description}
                    thousandSeparator=" "
                    displayType="text"
                  />
                ) : (
                  description + `%`
                )}
                {term !== `Total` && (
                  <div
                    className="bar"
                    style={{ width: description + `%` }}
                  ></div>
                )}
              </dd>
            </dl>
          ))}
        </Info>
      )}
    </Popup>
  )
}

Tooltip.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Tooltip
