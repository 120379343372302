import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTable, useSortBy } from "react-table"
import styled, { css } from "styled-components"
import { rem } from "polished"
import NumberFormat from "react-number-format"
import { startsWith } from "lodash"

import { StoreConsumer } from "../../store"
import { normalizePanelBookHeader } from "../../utils/panelbook"

const mask = (size) => css`
  margin: 0 -${rem(size)};
  padding: 0 ${rem(size)};
  mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) ${rem(size)},
    rgba(0, 0, 0, 1) calc(100% - ${rem(size)}),
    rgba(0, 0, 0, 0) 100%
  );
`

const SortToggle = styled.button.attrs({ type: `button` })`
  position: relative;
  padding-right: 1.25em;

  &:hover {
    color: ${(props) => props.theme.colorPurpleLight};
  }

  &::after {
    content: "";
    position: absolute;
    top: 0.5em;
    right: 0;

    ${(props) =>
      props.mode &&
      css`
        width: 0;
        height: 0;
        border: 0.313em solid transparent;
      `}

    ${(props) =>
      props.mode === `asc` &&
      css`
        border-top-color: currentColor;
        border-bottom-width: 0;
      `}

    ${(props) =>
      props.mode === `desc` &&
      css`
        border-bottom-color: currentColor;
        border-top-width: 0;
      `}

    ${(props) =>
      !props.mode &&
      css`
        width: 0.4em;
        height: 0.4em;
        border-radius: 50%;
        background-color: currentColor;
        opacity: 0.2;
      `}
  }
`

const Container = styled.div`
  ${mask(30)}
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @media ${(props) => props.theme.xsmallDown} {
    ${mask(15)}
  }

  table {
    table-layout: fixed;
    font-variant-numeric: tabular-nums;

    @media ${(props) => props.theme.largeDown} {
      min-width: ${rem(960)};
    }
  }

  .-numeric {
    text-align: right;

    ${SortToggle} {
      padding-right: 0;
      padding-left: 1.25em;

      &::after {
        right: auto;
        left: 0;
      }
    }
  }
`

const Table = ({ data }) => {
  const columns = useMemo(
    () =>
      Object.keys(data[0]).map((name) => ({
        accessor: name,
        Header: normalizePanelBookHeader(name),
      })),
    [data]
  )

  const initialState = { sortBy: [{ id: `Country`, asc: true }] }
  const disableSortRemove = true

  const table = useTable(
    { columns, data, initialState, disableSortRemove },
    useSortBy
  )

  return (
    <Container>
      <table {...table.getTableProps()} className="styled-table">
        <thead>
          {table.headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, ii) => (
                <th
                  key={ii}
                  className={column.id !== `Country` ? `-numeric` : undefined}
                >
                  <StoreConsumer>
                    {({ translations }) => (
                      <SortToggle
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        title={translations[`panelbook_table_sort`]}
                        mode={
                          column.isSorted
                            ? column.isSortedDesc
                              ? `asc`
                              : `desc`
                            : undefined
                        }
                      >
                        {startsWith(column.id, `_`) && `Age `}
                        {column.render(`Header`)}
                      </SortToggle>
                    )}
                  </StoreConsumer>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...table.getTableBodyProps()}>
          {table.rows.map((row, i) => (
            <React.Fragment key={i}>
              {table.prepareRow(row)}
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, ii) => (
                  <td
                    key={ii}
                    className={
                      cell.column.id === `Country` ? undefined : `-numeric`
                    }
                    {...cell.getCellProps()}
                  >
                    {cell.column.id === `Country` ? (
                      cell.render(`Cell`)
                    ) : (
                      <React.Fragment>
                        {cell.column.id === `Total` ? (
                          <NumberFormat
                            value={cell.value}
                            thousandSeparator=" "
                            displayType="text"
                          />
                        ) : (
                          <React.Fragment>{cell.value}%</React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Container>
  )
}

Table.propTypes = {
  data: PropTypes.array,
}

export default Table
