import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { StoreConsumer } from "../../store"

const Container = styled.div`
  input {
    width: 100%;
    height: 2.5em;
    display: block;
    border-bottom: 1px solid ${(props) => props.theme.colorGreyDark};
  }
`

const Filter = ({ value, onChange }) => {
  return (
    <StoreConsumer>
      {({ translations }) => (
        <Container>
          <input
            value={value}
            onChange={onChange}
            type="search"
            placeholder={translations[`panelbook_table_search`]}
            aria-label={translations[`panelbook_table_search`]}
          />
        </Container>
      )}
    </StoreConsumer>
  )
}

Filter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default Filter
